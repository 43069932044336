import styling from './Loader.module.scss'

const Loader = () => {
  return (
    <div>
      <span className={styling.loader}></span>
    </div>
  );
};

export default Loader;
