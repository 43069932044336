
/**
 * Formats a raw date string into a more concise and readable format.
 *
 * @param {string} rawDate - The raw date string to format (e.g., "2024-08-24T16:15:18.983+00:00").
 * @returns {string} - The formatted date string in the format "Mon DD, YYYY" (e.g., "Aug 24, 2024").
 */
export const formattedDate = (rawDate: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const date = new Date(rawDate);
  return date.toLocaleDateString("en-US", options);
};


// Function to format dates as Today, yesterday and general 
export function formatWordDate(date: Date): string {
  const currentDate = new Date();
  const targetDate = new Date(date);

  // Check if the date is today
  if (
    targetDate.getDate() === currentDate.getDate() &&
    targetDate.getMonth() === currentDate.getMonth() &&
    targetDate.getFullYear() === currentDate.getFullYear()
  ) {
    return "Today";
  }

  // Check if the date is yesterday
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (
    targetDate.getDate() === yesterday.getDate() &&
    targetDate.getMonth() === yesterday.getMonth() &&
    targetDate.getFullYear() === yesterday.getFullYear()
  ) {
    return "Yesterday";
  }

  // Otherwise, return the formatted date
  return targetDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}
